import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(20.000000, 27.000000)">
          <path
            d="M17.5469 25.9336V29H3.32812V25.9336H17.5469ZM4.07031 0.5625V29H0.300781V0.5625H4.07031ZM32.7812 3.08203L23.3672 29H19.5195L30.3594 0.5625H32.8398L32.7812 3.08203ZM40.6719 29L31.2383 3.08203L31.1797 0.5625H33.6602L44.5391 29H40.6719ZM40.1836 18.4727V21.5586H24.207V18.4727H40.1836Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;

// currentColor
