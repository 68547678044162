import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="LA" transform="translate(29.000000, 33.000000)">
        <path
          d="M17.5469 25.9336V29H3.32812V25.9336H17.5469ZM4.07031 0.5625V29H0.300781V0.5625H4.07031ZM32.7812 3.08203L23.3672 29H19.5195L30.3594 0.5625H32.8398L32.7812 3.08203ZM40.6719 29L31.2383 3.08203L31.1797 0.5625H33.6602L44.5391 29H40.6719ZM40.1836 18.4727V21.5586H24.207V18.4727H40.1836Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
